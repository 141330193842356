<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/message/screens' }"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>影厅管理</el-breadcrumb-item>
      <el-breadcrumb-item>影厅详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 头部卡片 -->
    <el-card class="headcard">
      <div slot="header">
        <span>{{ showData.名称 }}</span>
        <el-button style="float: right" type="danger" @click="deleteScreem()"
          >删除影厅</el-button
        >
      </div>
      <div class="setstatus">
        座位情况 :
        <span id="green"></span>
        {{ showData.可用座位数 }}
        <span id="red"></span>
        {{ showData.不可用座位数 }}
        <span id="grey"></span>
        {{ showData.不显示座位数 }}
      </div>
    </el-card>
    <!-- 底部卡片 -->
    <el-card>
      <div slot="header">
        <span style="fontweight: bold">座位分布图</span>

        <el-button
          type="danger"
          round
          style="float: right"
          @click="deleteSeat()"
          >删除</el-button>
        <el-button
          type="primary"
          round
          style="float: right"
          @click="updateSeat()"
          >保存</el-button>
        <el-button
          type="success"
          round
          style="float: right"
          @click="addDialogOpen()"
          >新建区域</el-button>

        <div id="tip">
          单击左键更改座位为损坏 单击右键更改座位为不存在 再次点击即可恢复正常
        </div>
        <hr />
        <template>
          <el-tabs v-model="areaName" @tab-click="handleClick">
            <el-tab-pane :label="item.areaName + '区'" :name="item.areaName" v-for="(item, i) in areaList" :key="i">

              <div class="seatarea">
                <div v-for="(item, i) in seatList" class="row" :key="i">
                  <div class="sitleft">
                    {{ item.排号 }}
                  </div>
                  <div
                    :class="[
                      'sit',
                      { 'bg-sited': item1.状态 == 2 },
                      { 'bg-sit': item1.状态 == 1 },
                      { 'bg-nosit': item1.状态 == 3 },
                    ]"
                    v-for="(item1, j) in item.座位" :key="j"
                    @click="warnState($event, item1.ID,item.排号)"
                    @contextmenu.prevent="noneState($event, item1.ID,item.排号)"
                  >
                  <div v-if="item1['名称']" class="seat-num">
                      {{ item1['名称'] | formatSeat }}
                  </div>
                  <div v-else class="seat-num" style="opacity: 0;">
                    {{ 0 }}
                  </div>
                </div>
                </div>
              </div>

            </el-tab-pane>
          </el-tabs>
        </template>
      </div>
      <div></div>
    </el-card>


    <!-- 添加影厅对话框 -->
    <el-dialog
      title="添加区域"
      :visible.sync="addDialogVisible"
      width="50%"
      @closed="addHandleClose"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRef"
        label-width="100px"
      >
        <el-form-item label="影厅名称" prop="名称">
          <el-input v-model="showData.名称" disabled></el-input>
        </el-form-item>
        <el-form-item label="区域名称" prop="区域名">
          <el-input v-model="addForm.区域名"></el-input>
        </el-form-item>
        <el-form-item label="影厅行数" prop="row">
          <el-input v-model="addForm.row"></el-input>
        </el-form-item>
        <el-form-item label="影厅列数" prop="col">
          <el-input v-model="addForm.col"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addArea()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "detalied",
  data() {
    return {
      id: this.$route.query.id,
      showData: {},
      seatList: [],
      areaList: [],
      warnseats: [],
      noneseats: [],
      normalseats: [],
      areaName: '',

      // 添加区域数据
      addForm: {
        影厅id: this.id,
        区域名: "",
        row: "",
        col: "",
      },
      // 添加数据验证规则
      addFormRules: {
        区域名: [{ required: true, message: "请输入区域名称", trigger: "blur" }],
        row: [
          { required: true, message: "请输入座位行数", trigger: "blur" },
          {
            type: "number",
            message: "请输入数字格式",
            trigger: "blur",
            transform(value) {
              return Number(value);
            },
          },
        ],
        col: [
          { required: true, message: "请输入座位列数", trigger: "blur" },
          {
            type: "number",
            message: "请输入数字格式",
            trigger: "blur",
            transform(value) {
              return Number(value);
            },
          },
        ],
      },
      addDialogVisible: false,
    };
  },
  created() {
    this.showInFoById();
  },
  methods: {
    // 查找影厅信息
    //查找座位信息
    showInFoById() {
      this.$axios({
        url: "/manager/hallDetail?id=" + this.id,
      })
        .then((res) => {
          // console.log(res.data);
          this.showData = res.data;
          //座位列表
          this.areaList = res.data.区域;
          this.areaName = this.areaList[0].areaName;
          this.getSeatList()
        })
        .catch((err) => err);
    },

    getSeatList() {
      this.$axios({
        url: "/manager/areaDetail?id=" + this.id + "&areaName=" + this.areaName,
      })
        .then((res) => {
          console.log(res.data);
          this.seatList = res.data.座位;
        })
        .catch((err) => err);
    },
    handleClick() {
      this.seatList = []
      this.noneseats = []
      this.normalseats = []
      this.warnseats = []
      this.getSeatList()
    },

    //删除影厅
    deleteScreem() {
      let id = this.$route.query.id;
      this.$confirm("此操作将永久删除该影厅信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.$axios({
            method: "post",
            url: "/manager/deleteHall",
            data: {
              id
            }
          })
            .then((res) => {
              // console.log(res);
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                // 返回上级
                this.$router.go(-1);
              } else {
                this.$message({
                  type: "warning",
                  message: "删除失败",
                });
              }
            })
            .catch((err) => err);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //改变座位情况
    warnState(event, ID, row) {
      if (event.target.className == "sit bg-sit") {
        this.normalseats = this.changeState(this.normalseats,ID)
        this.warnseats.push({ID:ID,row:row})
        // console.log("左键点击了" + i+" " + j);
        event.target.className = "sit bg-sited";
      } else if (event.target.className == "sit bg-sited") {
        this.warnseats=this.changeState(this.warnseats,ID)
        this.normalseats.push({ID:ID,row:row})
        // console.log(this.warnseats);
        event.target.className = "sit bg-sit";
      }
    },
    noneState(event,  ID, row) {

      if (event.target.className == "sit bg-sit") {
        this.normalseats = this.changeState(this.normalseats,ID)
        this.noneseats.push({ID:ID,row:row})

        // console.log("右键点击了" + i+" " + j);
        event.target.className = "sit bg-nosit";
      } else if (event.target.className == "sit bg-nosit") {
        this.noneseats = this.changeState(this.noneseats,ID)
        this.normalseats.push({ID:ID,row:row})
        event.target.className = "sit bg-sit";
      }
    },
    changeState(array, ID) {
      const index = array.findIndex(item => item.ID === ID);
      if (index !== -1) {
        array.splice(index, 1);
      }
      return array
    },
    //更新座位信息
    updateSeat() {
      // let arrjson = JSON.stringify(arr);
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      this.$axios({
        method: "post",
        url: "/manager/editArea",
        data: {
          warnseats: this.warnseats,
          noneseats: this.noneseats,
          hallId: this.id,
          areaName: this.areaName,
          normalseats: this.normalseats,
        },
      })
        .then((res) => {
          // console.log(res);
          loading.close()
          if (res.code == 200) {
            this.$message.success("修改成功!")
            this.showInFoById()
          } else {
            this.$message.warning(res.msg)
          }
        })
        .catch((err) => {
          console.log(err.message)
          loading.close()
        });
    },

    //打开添加对话框
    addDialogOpen() {
      this.addDialogVisible = true;
    },
    //关闭添加影厅弹框
    addHandleClose() {
      this.$refs.addFormRef.resetFields();
    },

    //添加区域
    addArea() {
      this.$refs.addFormRef.validate((valid) => {
        if (!valid) {
          return;
        }
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        this.addForm.影厅id = this.id
        this.$axios({
          method: "post",
          url: "/manager/addArea",
          data: this.addForm,
        })
          .then((res) => {
            // console.log(res);
            loading.close()
            if (res.code == 200) {
              this.$message.success("添加成功");
              this.addDialogVisible = false;
              this.showInFoById()
            } else {
              this.$message.warning(res.msg);
            }
          })
          .catch((err) => {
            console.log(err.message)
            loading.close()
          });
      });
    },

    //删除区域信息
    deleteSeat() {
      this.$confirm(`此操作将永久删除${ this.areaName + "区" }信息, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        this.$axios({
          method: "post",
          url: "/manager/deleteArea",
          data: {
            id: this.id,
            areaName: this.areaName,
          },
        })
          .then((res) => {
            // console.log(res);
            loading.close()
            if (res.code == 200) {
              this.$message.success("删除成功!")
              this.showInFoById()
            } else {
              this.$message.warning(res.msg)
            }
          })
          .catch((err) => {
            console.log(err.message)
            loading.close()
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  filters: {
    formatSeat(str) {
      if (str.length > 0) {
        const num = str.match(/\d+/g)[1];
        return num
      }
      return str
    }
  }
};
</script>

<style scoped>
hr {
  margin: 20px 5px;
}
.headcard {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: bold;
}
.setstatus {
  font-size: 14px;
  font-weight: normal;
}
.setstatus span {
  display: inline-block;
  width: 20px;
  height: 20px;
}
#green {
    background: url('../../assets/img/selected.png') center center no-repeat;
    background-size: 100% 100%;
}
#red {
    background: url('../../assets/img/bought.png') center center no-repeat;
    background-size: 100% 100%;
}
#grey {
    background: url('../../assets/img/unselected.png') center center no-repeat;
    background-size: 100% 100%;
}
.seatarea {
  /* float: left; */
  /* margin: 20px;
  width: 2000px;
  height: 2000px; */

   /* float: left; */
   margin: 20px;
  /* width: 2000px; */
  /* height: 2000px; */
  overflow: auto;
  white-space: nowrap;
  padding: 10px;

}
.sit {
  display: inline-block;
  height: 15px;
  width: 15px;
  min-height: 10px;
  min-width: 10px;
  margin: 2px 2px 2px 2px;
  background-size: cover;

  position: static;

}
.sitleft {
  display: inline-block;
  margin: 4px 4px 4px 4px;
  /* line-height: 20px; */
  position: relative;
  width: 20px;
  /* height: 20px; */
  /* top: -6px; */
  top:5px;
  text-align: center;
}

/* 可选座位 */
.bg-sit {
    background: url('../../assets/img/selected.png') center center no-repeat;
    background-size: 100% 100%;
}

.bg-sit:hover {
  cursor: pointer;
}

/* 损坏座位 */
.bg-sited {
    background: url('../../assets/img/bought.png') center center no-repeat;
    background-size: 100% 100%;
}

/* 无座 */
.bg-nosit {
    background: url('../../assets/img/unselected.png') center center no-repeat;
    background-size: 100% 100%;
}

.row {
  /* 清除格式并换行 */
  height: 30px;
  clear: both;
}
#tip {
  color: #8c939d;
  margin-top: 5px;
  font-size: 14px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20%;
}

.el-tag {
  margin-left: 10px;
  margin-top: 10px;
}

.seat-num {
  position: relative;
  top: -14px;
  color: #0e0e0e;
  font-size: 10px;
  /* border: 1px solid red; */
  text-align: center;
}

:deep .el-tabs__content {
  overflow: auto;
}

</style>
